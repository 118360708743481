<template>
  <div>
    <v-container fluid>
      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
                color="primary"
                indeterminate
                size="50"
        ></v-progress-circular>
      </v-overlay>
      <v-row>

        <v-col cols="8">
          <Toolbar/>
        </v-col>

        <v-col v-if="can('Choisir tous')" class="text-right" cols="4">

          <v-btn :class="selectedTasks.length===0 ?'' :'white--text'"
                 :disabled="selectedTasks.length===0"
                 class="mt-7 mr-2"
                 color="red"
                 depressed
                 @click="refuseDialog = true">
            <v-icon left>mdi-close-circle-outline</v-icon>
            Refuser
          </v-btn>

          <v-btn :disabled="selectedTasks.length===0"
                 class="mt-7"
                 color="success"
                 depressed
                 @click="validateAllTask">
            <v-icon left>mdi-check-circle-outline</v-icon>
            Valider
          </v-btn>

        </v-col>

        <v-col cols="12">
          <v-card class="rounded-lg shadow">

            <v-card-title>
              <div>
                <v-text-field v-model="keyword"
                              append-icon="mdi-magnify"
                              class="w-300"
                              clearable
                              dense
                              filled
                              hide-details
                              placeholder="Recherche..."
                              rounded single-line></v-text-field>
              </div>
              <v-spacer/>
              <v-btn class="text-none" disabled text>
                {{ pending }} Élément(s)
              </v-btn>
              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" @click="refresh" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>
              <v-tooltip color="primary" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs"
                         @click="[drawer = true]"
                         v-on="on">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <span>Filtrer</span>
              </v-tooltip>
            </v-card-title>

            <v-skeleton-loader v-if="task_loading" type="table"/>

            <v-card-text v-if="!task_loading && tasks.length === 0" class="text-center">
              <NoResult/>
            </v-card-text>

            <div v-if="!task_loading && tasks.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th v-if="can('Choisir tous')"
                        class="text-left">
                      <v-icon :color="selectedTasks.length > 0 ? 'blue' : ''"
                              @click="toggle" @mousedown.prevent>
                        {{ icon }}
                      </v-icon>
                    </th>
                    <th class="text-left">POS</th>
                    <th class="text-left">Campagne</th>
                    <th class="text-left">Tâche</th>
                    <th class="text-left">Montant du gain</th>
                    <th class="text-left">date de synchronisation</th>
                    <th class="text-left">Statut</th>
                    <th class="text-right">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in tasks" :key="item.id"
                      :class="selectedTasks.includes(item.id) ? 'v-data-table__selected' : ''">
                    <td v-if="can('Choisir tous')">
                      <v-checkbox v-model="selectedTasks" :value="item.id"
                                  class="pt-0 mt-0"
                                  color="blue" hide-details multiple></v-checkbox>
                    </td>
                    <td>
                      <span class="text--primary cursor-pointer"
                            @click="[salepoint = item.salepoint,salepointDialog=true]">{{ item.salepoint.name }}<span
                              class="font-weight-bold blue--text">+</span>
                      </span>
                    </td>
                    <td> {{ item.task.campaign.name }}</td>
                    <td>{{ item.task.name }}</td>
                    <td>
                      <v-chip class="font-weight-bold" color="blue" dark small>
                        {{ item.task.winning_points | toCurrency }}
                      </v-chip>
                    </td>
                    <td>{{ item.syncronized_at }}</td>
                    <td>
                      <v-chip :color="item.state.color" dark small>{{ item.state.value }}</v-chip>
                    </td>
                    <td class="text-right">
                      <v-tooltip color="primary" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mr-1" color="primary" icon v-bind="attrs"
                                 @click="showResponses(item)"
                                 v-on="on">
                            <v-icon>mdi-script-text-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Voir les réponses</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>


                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                    <div>
                        <v-select v-model="per_page"
                                  :items="[10,20,50,100,200,300,400,500]"
                                  :style="{width: '120px'}"
                                  dense
                                  hide-details
                                  label="Ligne par page"
                                  outlined></v-select>
                    </div>

                    <v-spacer/>
                    <v-pagination v-if="total > 10"
                                  v-model="pagination.current"
                                  :length="pagination.total"
                                  circle
                                  total-visible="6"
                                  @input="onPageChange"
                    ></v-pagination>
                </v-card-actions>


            </div>

          </v-card>
        </v-col>
      </v-row>

      <!--Dialogs && Drawer-->
      <ResponsesDialog ref="resDialog"
                       :dialog.sync="responsesDialog"
                       :task.sync="task"
                       @get-tasks="getTasks"/>

      <SalepointDialog v-if="salepoint"
                       :dialog.sync="salepointDialog"
                       :salepoint.sync="salepoint"/>

      <RefuseDialog :dialog.sync="refuseDialog"
                    :selected-tasks.sync="selectedTasks"
                    @get-tasks="[getTasks(),selectedTasks=[]]"/>

      <v-navigation-drawer
              v-model="drawer"
              :permanent="drawer" app
              clipped
              floating
              right
              temporary
              width="400">
        <v-list-item>
          <v-list-item-avatar color="blue">
            <v-icon dark>mdi-tune</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Filtrages</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon @click="drawer = false">mdi-close</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider/>
        <FilterDrawer ref="FilterDrawer" @filter="[getTasks($event),drawer=false]"/>
      </v-navigation-drawer>
      <!--Dialogs && Drawer-->

    </v-container>
  </div>
</template>

<script>
import Toolbar from "../Toolbar";
import ResponsesDialog from "../Dialogs/ResponsesDialog";
import SalepointDialog from "../Dialogs/SalepointDialog";
import {HTTP} from "@/http-common";
import RefuseDialog from "./Dialogs/RefuseAllDialog";
import FilterDrawer from "./FilterDrawer";
import NoResult from "../../../components/NoResult";

export default {
    components: {NoResult, RefuseDialog, Toolbar, SalepointDialog, ResponsesDialog, FilterDrawer},
    data() {
        return {
            task_loading: false,
            overlay: false,

            tasks: [],
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },

            pending: '',
            task_id: null,
            salepoint_id: null,
            responsesDialog: false,
            task: '',
            salepoint: '',
            salepointDialog: false,
            selectedTasks: [],
            refuseDialog: false,

            keyword: '',
            filter: {},
            drawer: false
        }
    },
    methods: {
        getTasks(filter) {
            this.filter = filter ? filter : this.filter
            this.task_loading = true
            this.$Progress.start()
            HTTP.get('/tasks/temp?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: this.per_page,
                    filter: this.filter,
                }
            }).then((res) => {
                this.task_loading = false
                this.$Progress.finish()
                this.tasks = res.data.data.data
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.total = res.data.data.total;
                this.pending = res.data.pending;
            }).catch(err => {
                this.task_loading = false
                this.$Progress.fail()
                console.log(err)
            })
        },
        onPageChange() {
            this.getTasks()
        },
        refresh() {
            this.filter = {}
            this.getTasks()
        },
        showResponses(task) {
            this.task = task
            this.responsesDialog = true
            this.$refs.resDialog.showResponses(task.salepoint_id, task.task_id)
        },
        toggle() {
            if (this.selectedTasks.length === this.tasks.length) {
                this.selectedTasks = []
            } else {
                this.selectedTasks = this.tasks.map(el => el.id)
            }
        },
        validateAllTask() {
            this.$Progress.start()
            this.overlay = true
            HTTP.post('/tasks/temp/validate-all', {
                task_salepoint_ids: this.selectedTasks
            }).then(() => {
                this.overlay = false
                this.$Progress.finish()
                this.$successMessage = 'Ces tâches ont été validées avec succès'
                this.getTasks()
                this.selectedTasks = []
            }).catch(err => {
                this.$Progress.fail()
                this.overlay = false
                console.log(err)
            })
        },
    },
    computed: {
        icon() {
            if (this.selectedTasks.length === this.tasks.length) return 'mdi-close-box'
            if (this.selectedTasks.length > 0 && this.selectedTasks.length !== this.tasks.length) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    created() {
        this.getTasks()
    },
    watch: {
        '$is_confirm': function (el) {
            if (el) {
                this.$is_confirm = false
                this.overlay = true
                this.changeStatus()
            }
        },
        per_page() {
            this.pagination.current = 1
            this.getTasks();
        },
        keyword() {
            this.getTasks();
        },
    },
}
</script>

<style scoped>

</style>